





















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class CmxCateList extends Vue {
  contents: object[] = [];
 isshow: boolean=false;
 // 获取关于我们cms内容
 getContents () {
   this.$Api.cms.getContentsByCatKeyEx({ Key: 'Home', Page: 1, PageSize: 6 }).then((result) => {
     this.contents = result.Data;
   });
 }
 showLang () {
   if (localStorage.locale === 'E') this.isshow = true;
 }
 mounted () {
   this.getContents();
   this.showLang();
 }
}
